<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ma-2"
        depressed
        color="teal"
        dark
        small
        v-bind="attrs"
        v-on="on"
      >
        设置
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">设置</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-switch
                label="启用打字机效果"
                color="indigo"
                :input-value="true"
                @change="updateTypewriterMode"
                inset
                hide-details
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapState('chat', ['typewriterMode'])
  },
  methods: {
    ...mapMutations('chat', ['updateTypewriterMode'])
  }
}
</script>

<style lang="scss" scoped>

</style>
