<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="290"
  >
    <v-card>
      <v-card-title class="text-h5">
        余量提醒
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader
          v-if="loading"
          class="mx-auto"
          max-width="300"
          type="text"
        ></v-skeleton-loader>
        <div v-else>{{text}}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="dialog = false"
        >
          确定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { announcement } from '@/apis/chat'

export default {
  data () {
    return {
      dialog: true,
      loading: false,
      text: ''
    }
  },
  mounted () {
    this.loading = true
    announcement().then(resp => {
      const text = resp.data
      this.text = text
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
