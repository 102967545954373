<template>
  <div class="markdown" v-html="renderedHTML"></div>
</template>

<script>
import hljs from 'highlight.js'
import { marked } from 'marked/lib/marked.esm'
// import 'highlight.js/styles/atom-one-dark.css'
import 'highlight.js/styles/atom-one-light.css'

const renderer = new marked.Renderer()

export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    renderedHTML () {
      marked.setOptions({
        renderer: renderer,
        gfm: true
      })
      return marked.parse(this.content)
    }
  },
  mounted () {
    hljs.highlightAll()
  }
}
</script>

<style lang="scss" scoped>
.markdown {
  padding-top: 0 !important;
  ::v-deep {
    p {
      margin-bottom: 0;
      padding-top: 0;
    }
  }
}
</style>
